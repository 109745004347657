@charset "UTF-8";

// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "index";
@use "mixin" as mixin;
@use "color" as color;

// ==================================================
//  Hide
// ==================================================
.hide {
  &-pc {
    @include mixin.pc {
      display: none !important;
    }
  }
  &-sp {
    @include mixin.sp {
      display: none !important;
    }
  }
}

// ==================================================
//  Body
// ==================================================
body {
  font-family: "Kiwi Maru", "Hiragino Mincho ProN", YuMincho, "Yu Mincho", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.8;
  min-width: 1400px;
  color: color.$site-write;

  @include mixin.sp {
    font-size: 1.4rem;
    min-width: 375px;
  }
}

// ==================================================
//  Body Inner
// ==================================================
.bodyInner {
  display: block;
  width: 100%;
  border-top: 11px solid #fbe0dd;
  background-color: #fff;

  @include mixin.pc {
    padding-left: 320px;
  }

  @include mixin.sp {
    border-top-width: 5px;
  }
}

// ==================================================
//  Page Inner
// ==================================================
.pageInner {
  display: block;
  width: 100%;
  position: relative;
  z-index: 20;
}

// ==================================================
//  Header
// ==================================================
.header {
  display: block;
  width: 100%;
  max-width: 320px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 80;
  border-top: 11px solid #fbe0dd;
  background-color: #fff;
  padding: 46px 60px 46px 48px;
  overflow: auto;

  @include mixin.sp {
    border-top-width: 5px;
    max-width: none;
    padding: 105px 40px 50px;
  }

  // Javascript
  &.js-headerMenu {
    @include mixin.sp {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.45s ease, visibility 0.45s ease;
    }

    &-active {
      @include mixin.sp {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &_inner {
    display: block;
    width: 100%;
    height: 100%;

    @include mixin.sp {
      height: auto;
    }
  }

  &_logo {
    display: block;
    width: 100%;
    max-width: 212px;
    margin: 0 auto 46px;

    @include mixin.sp {
      display: none;
    }

    a,
    img {
      display: block;
      width: 100%;
    }

    a {
      @include mixin.hoverOpacity60;
    }
  }

  &_navigation {
    display: block;
    width: 100%;

    &_menu {
      display: block;
      width: 100%;
      margin: 0 0 34px;
      padding: 0;
      list-style: none;

      @include mixin.sp {
        margin-bottom: 26px;
      }

      &_item {
        font-size: 2rem;
        line-height: calc(29 / 20);
        color: #ed7270;
        border-top: 1px solid #e5e5e5;

        @include mixin.sp {
          font-size: 1.6rem;
        }

        &:last-child {
          border-bottom: 1px solid #e5e5e5;
        }

        &_en {
          @include mixin.font-shippori;
          display: block;
          font-size: 1.2rem;
          line-height: calc(17 / 12);
          margin-top: -3px;
        }

        &_inner {
          display: block;
          color: inherit;
          text-decoration: none;
          padding: 10px 23px 11px 21px;
          position: relative;
          @include mixin.hoverOpacity60;

          @include mixin.sp {
            padding: 14px 32px 14px 20px;
          }

          &::after {
            content: "";
            display: block;
            width: 6px;
            height: auto;
            aspect-ratio: 1/1;
            margin: 0 10px;
            border-top: 3px solid #ed6967;
            border-right: 3px solid #ed6967;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: rotate(45deg) translateY(-50%);

            @include mixin.sp {
              width: 5px;
              border-top-width: 2px;
              border-right-width: 2px;
            }
          }
        }
      }
    }

    &_banner {
      display: block;
      width: 100%;
      margin-top: 13px;
      @include mixin.hoverOpacity60;

      @include mixin.sp {
        width: calc(100% - 80px);
        margin: 12px auto 0;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }

  &_copyright {
    font-size: 1.3rem;
    line-height: calc(19 / 13);
    color: #afafaf;
    margin: 118px 0 0;

    @include mixin.sp {
      font-size: 1.2rem;
      text-align: center;
      margin-top: 40px;
    }
  }
}

// ==================================================
//  headerSP
// ==================================================
.headerSP {
  display: none;

  @include mixin.sp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 15px 14px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    background-color: #fff;
    border-top: 5px solid #fbe0dd;
  }

  &_logo {
    @include mixin.sp {
      display: block;
      width: 100%;
      max-width: 240px;
      margin: 0;
    }

    & > img {
      @include mixin.sp {
        display: block;
        width: 100%;
      }
    }
  }

  &_menuButton {
    @include mixin.sp {
      display: block;
      width: 56px;
      height: auto;
      aspect-ratio: 1/1;
      overflow: hidden;
      text-indent: 100%;
      white-space: nowrap;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 50px auto;
      background-color: #fdefec;
    }

    // Javascrip
    &.js-headerMenuButton {
      @include mixin.sp {
        background-image: url(../img/common/ico_menu.png);
      }
      &-active {
        @include mixin.sp {
          background-image: url(../img/common/ico_menu_close.png);
        }
      }
    }
  }
}

// ==================================================
//  Main
// ==================================================
.main {
  display: block;
  width: 100%;

  @include mixin.sp {
    margin-top: 85px;
  }

  // Head
  &_head {
    display: block;
    width: 100%;
    padding: 46px 52px 35px 68px;
    margin: 0 auto;
    border-radius: 0 0 0 50px;
    background-color: #fdefec;

    @include mixin.sp {
      padding: 20px 0 30px;
      border-radius: 0 0 0 30px;
    }

    &_inner {
      display: flex;
      column-gap: 89px;
      width: 100%;
      max-width: 1080px;
      margin: 0 auto;
      position: relative;

      @include mixin.sp {
        flex-direction: column;
        column-gap: 0;
        row-gap: 10px;
        width: calc(100% - 40px);
      }
    }

    &_title {
      white-space: nowrap;
      color: color.$site-main;

      @include mixin.sp {
        white-space: normal;
      }

      &_ja {
        display: block;
        font-size: 3.6rem;
        line-height: calc(52 / 36);
        font-weight: 500;
        margin: 0;

        @include mixin.sp {
          font-size: 2.8rem;
          line-height: calc(36 / 28);
          margin-bottom: 8px;
        }

        // Modifier
        &-small {
          font-size: 1.9rem;
          line-height: calc(26 / 19);
          letter-spacing: -0.001em;

          @include mixin.sp {
            font-size: 1.5rem;
            margin: 8px 0 4px;
          }
        }
      }

      &_en {
        display: block;
        font-size: 2.3rem;
        line-height: calc(34 / 23);
        font-weight: 500;
        margin: -6px 0 0 0;

        @include mixin.sp {
          font-size: 1.5rem;
          margin-top: 1px;
        }
      }
    }

    &_description {
      font-size: 1.5rem;
      line-height: calc(21 / 15);
      font-weight: 500;
      color: #838383;
      margin: 7px 0 0 0;

      @include mixin.sp {
        font-size: 1.3rem;
        margin-top: 6px;
      }
    }

    &_label {
      display: block;
      width: 100%;

      @include mixin.pc {
        position: absolute;
        top: -30px;
        right: 0;
      }

      // Modifier
      &-kaigo {
        max-width: 227px;

        @include mixin.sp {
          max-width: 114px;
        }
      }
      &-iryo {
        max-width: 227px;

        @include mixin.sp {
          max-width: 114px;
        }
      }
      &-kenko {
        max-width: 227px;

        @include mixin.sp {
          max-width: 114px;
        }
      }
      &-chiiki {
        max-width: 227px;

        @include mixin.sp {
          max-width: 114px;
        }
      }
      &-kyoiku {
        max-width: 310px;

        @include mixin.sp {
          max-width: 156px;
        }
      }
      &-yobo {
        max-width: 227px;

        @include mixin.sp {
          max-width: 114px;
        }
      }
    }
  }

  // Content
  &_content {
    display: block;
    width: 100%;
    max-width: 960px;
    margin: 80px auto 0;

    @include mixin.sp {
      width: calc(100% - 40px);
      margin-top: 50px;
    }

    & > :first-child {
      margin-top: 0 !important;
    }
    & > :last-child {
      margin-bottom: 0 !important;
    }

    h2 {
      font-size: 2.4rem;
      line-height: calc(28 / 24);
      font-weight: 500;
      margin: 45px 0 25px;
      padding-bottom: 12px;
      border-bottom: 2px solid color.$site-main;

      @include mixin.sp {
        font-size: 2rem;
        margin: 32px 0 16px;
        padding-bottom: 10px;
      }
    }

    p {
      font-size: 1.6rem;
      line-height: calc(28 / 16);
      margin: 16px 0 0;

      @include mixin.sp {
        font-size: 1.4rem;
        margin-top: 14px;
      }
    }

    ul,
    ol {
      font-size: 1.6rem;
      line-height: calc(28 / 16);
      margin-top: 16px;

      @include mixin.sp {
        font-size: 1.4rem;
        margin-top: 14px;
      }

      @include mixin.sp {
        padding-left: 1.5em;
      }
    }
  }
}

// ==================================================
//  Footer
// ==================================================
.footer {
  display: block;
  width: 100%;
  position: relative;
  z-index: 20;
  margin-top: 255px;

  @include mixin.sp {
    margin-top: 80px;
  }

  &_head {
    display: block;
    width: 100%;
    padding: 31px 0 16px;
    background-color: #fff;

    @include mixin.sp {
      padding: 24px 0 16px;
    }

    &_inner {
      display: block;
      width: 100%;
      max-width: 884px;
      margin: 0 auto;

      @include mixin.sp {
        width: calc(100% - 40px);
      }
    }

    &_logo {
      display: block;
      width: 100%;
      max-width: 262px;

      @include mixin.sp {
        max-width: 220px;
      }

      & > img {
        display: block;
        width: 100%;
      }
    }
  }

  &_navigation {
    display: block;
    width: 100%;
    background-color: color.$site-main;
    padding: 27px 0 42px;

    @include mixin.sp {
      padding: 36px 0;
    }

    &_inner {
      display: flex;
      flex-wrap: wrap;
      column-gap: 77px;
      width: 100%;
      max-width: 884px;
      margin: 0 auto;

      @include mixin.sp {
        column-gap: 0;
        row-gap: 20px;
        width: calc(100% - 40px);
      }
    }

    &_menu {
      display: block;

      @include mixin.sp {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        row-gap: 10px;
      }

      & > :first-child {
        margin-top: 0;
      }

      &_title {
        display: block;
        font-size: 1.8rem;
        line-height: calc(26 / 18);
        padding-bottom: 4px;
        border-bottom: 1px solid #fff;
        color: #fff;
        margin: 25px 0 15px 0;

        @include mixin.pc {
          min-width: 155px;
        }

        @include mixin.sp {
          width: 100%;
          font-size: 1.6rem;
          padding-bottom: 8px;
          margin: 15px 0 0 0;
        }
      }

      &_list {
        display: block;
        width: 100%;
        list-style: none;
        margin: 15px 0 0;
        padding: 0;

        @include mixin.sp {
          display: flex;
          flex-wrap: wrap;
          column-gap: 10px;
          row-gap: 8px;
          margin-top: 0;
        }

        &_item {
          font-size: 1.5rem;
          line-height: calc(21 / 15);
          color: #fff;
          margin-top: 5px;

          @include mixin.sp {
            width: calc((100% / 2) - (10px / 2));
            font-size: 1.4rem;
            margin-top: 0;
          }

          &_inner {
            color: inherit;
            text-decoration: none;
            @include mixin.hoverOpacity60;

            br {
              @include mixin.sp {
                display: none;
              }
            }
          }
        }
      }

      &_link {
        display: block;
        font-size: 1.8rem;
        line-height: calc(31 / 18);
        color: #fff;
        text-decoration: none;
        @include mixin.hoverOpacity60;

        @include mixin.sp {
          width: calc((100% / 2) - (10px / 2));
          font-size: 1.6rem;
        }
      }
    }
  }
}

// ==================================================
//  About
// ==================================================
.about {
  display: block;
  width: 100%;
  background-color: #fff;
  border-radius: 0 0 0 50px;

  @include mixin.sp {
    border-radius: 0 0 0 30px;
  }

  &_inner {
    display: block;
    width: 100%;
    border-radius: 50px 0 0 50px;
    overflow: hidden;
    background-color: #fdefec;
    padding-bottom: 74px;

    @include mixin.sp {
      border-radius: 30px 0 0 30px;
      padding: 10px 0 40px;
    }
  }

  &_visual {
    display: block;
    width: 100%;
    max-width: 905px;
    margin: 0 auto;
    transform: translate(144px, 73px);

    @include mixin.sp {
      transform: translate(30px, 20px);
    }
  }

  &_message {
    display: block;
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    padding: 39px 68px 0 55px;

    @include mixin.sp {
      width: calc(100% - 40px);
      padding: 0;
    }

    &_title {
      display: block;
      width: 100%;
      max-width: 573px;
      margin: 0;

      @include mixin.sp {
        position: relative;
        z-index: 2;
      }
    }

    &_content {
      display: flex;
      column-gap: 74px;
      width: 100%;
      margin-top: -27px;

      @include mixin.sp {
        flex-direction: column;
        column-gap: 0;
        margin-top: 0;
      }

      &_text {
        display: block;
        width: 100%;
        max-width: calc(100% - 45% - 74px);
        padding: calc(27px + 48px) 0 0 33px;

        @include mixin.sp {
          max-width: none;
          padding: 20px 20px 0;
        }

        & > :first-child {
          margin-top: 0 !important;
        }

        &_paragraph {
          font-size: 1.6rem;
          line-height: calc(30 / 16);
          margin: 16px 0 0;
          color: #838383;

          @include mixin.sp {
            font-size: 1.4rem;
            margin-top: 14px;
          }
        }

        &_link {
          display: block;
          width: 100%;
          max-width: 129px;
          text-align: center;
          background-color: #fce8e5;
          border: 1px solid #ed7270;
          border-radius: 20px;
          text-decoration: none;
          @include mixin.font-shippori;
          font-size: 1.6rem;
          font-weight: 400;
          line-height: calc(16 / 16);
          padding: 10px 6px 13px;
          position: relative;
          margin: 111px 0 0 auto;
          @include mixin.hoverOpacity60;

          @include mixin.sp {
            max-width: 100px;
            padding: 6px 10px 12px;
            margin: 22px 0 0 auto;
          }

          &::before {
            content: "";
            display: block;
            width: 42px;
            height: 1px;
            background-color: #ed7270;
            position: absolute;
            top: 50%;
            left: -42px;
            transform: translateY(-50%);

            @include mixin.sp {
              width: 32px;
              left: -32px;
            }
          }
        }
      }

      &_image {
        display: block;
        width: 100%;
        max-width: 45%;
        height: auto;
        aspect-ratio: 1/1;
        border-radius: 100%;
        overflow: hidden;

        @include mixin.sp {
          max-width: 120px;
          margin: -35px 0 0 0;
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &_support {
    display: block;
    width: 100%;

    @include mixin.sp {
      padding-top: 30px;
    }

    &_symbol {
      display: block;
      width: 100%;
      max-width: 122px;
      margin: 0 auto 44px;

      @include mixin.sp {
        max-width: 90px;
        margin-bottom: 25px;
      }
    }

    &_description {
      font-size: 1.9rem;
      line-height: calc(31 / 19);
      text-align: center;
      margin: 19px 0 0;
      color: color.$site-write;

      @include mixin.sp {
        font-size: 1.4rem;
        margin-top: 15px;
      }
    }

    &_list {
      display: flex;
      flex-wrap: wrap;
      column-gap: 33px;
      row-gap: 135px;
      width: 100%;
      max-width: 946px;
      margin: 142px auto 0;

      @include mixin.sp {
        column-gap: 18px;
        row-gap: 76px;
        width: calc(100% - 40px);
        margin-top: 80px;
      }

      &_item {
        display: block;
        width: calc((100% / 3) - (66px / 3));
        background-color: #fff;
        border-radius: 30px;
        border: 3px solid color.$site-main;
        padding: 0 18px 40px;

        @include mixin.sp {
          width: calc((100% / 2) - (18px / 2));
          border-radius: 25px;
          border-width: 2px;
          padding: 0 10px 22px;
        }

        // Modifier
        &-iryo {
          border-color: color.$site-support-iryo;

          .about_support_list_item_title {
            color: color.$site-support-iryo;
          }

          .about_support_list_item_link {
            background-color: color.$site-support-iryo;
          }
        }
        &-yobo {
          border-color: color.$site-support-yobo;

          .about_support_list_item_title {
            color: color.$site-support-yobo;
          }

          .about_support_list_item_link {
            background-color: color.$site-support-yobo;
          }
        }
        &-kaigo {
          border-color: color.$site-support-kaigo;

          .about_support_list_item_title {
            color: color.$site-support-kaigo;
          }

          .about_support_list_item_link {
            background-color: color.$site-support-kaigo;
          }
        }
        &-kyoiku {
          border-color: color.$site-support-kyoiku;

          .about_support_list_item_title {
            color: color.$site-support-kyoiku;
          }

          .about_support_list_item_link {
            background-color: color.$site-support-kyoiku;
          }
        }
        &-kenko {
          border-color: color.$site-support-kenko;

          .about_support_list_item_title {
            color: color.$site-support-kenko;
          }

          .about_support_list_item_link {
            background-color: color.$site-support-kenko;
          }
        }
        &-chiiki {
          border-color: color.$site-support-chiiki;

          .about_support_list_item_title {
            color: color.$site-support-chiiki;
          }

          .about_support_list_item_link {
            background-color: color.$site-support-chiiki;
          }
        }

        &_icon {
          display: block;
          width: 100%;
          max-width: 159px;
          margin: -82px auto -20px;

          @include mixin.sp {
            max-width: 110px;
            margin-top: -60px;
            margin-bottom: -10px;
          }
        }

        &_title {
          font-size: 3.6rem;
          line-height: calc(52 / 36);
          margin: 0 0 21px;
          color: color.$site-main;
          text-align: center;
          position: relative;

          @include mixin.sp {
            font-size: 2.4rem;
            margin-bottom: 14px;
          }
        }

        &_description {
          font-size: 1.6rem;
          line-height: calc(20 / 16);
          margin: 16px 0 0;
          color: color.$site-write;

          @include mixin.pc {
            min-height: 100px;
          }

          @include mixin.sp {
            font-size: 1.4rem;
            margin-top: 14px;
          }
        }

        &_link {
          display: block;
          width: 100%;
          max-width: 167px;
          margin: 26px auto 0;
          font-size: 1.8rem;
          line-height: calc(26 / 18);
          border-radius: 48px;
          color: #fff;
          text-decoration: none;
          background-color: color.$site-main;
          text-align: center;
          padding: 11px;
          @include mixin.hoverOpacity60;

          @include mixin.sp {
            max-width: 115px;
            margin-top: 18px;
            font-size: 1.4rem;
            border-radius: 30px;
            padding: 8px;
          }
        }
      }
    }
  }
}
